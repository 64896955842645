import React from 'react';
// Structure imports
import Layout from '@components/structure/Layout';
// Utility imports
import Seo from '@components/utility/Seo';


const TermsConditions = () => (
	<Layout pageType="termsCondition">
		<Seo title='Terms and Conditions' description='Description of homepage' />

		<section className='container termsConditions'>
			<p className='header center'>SMS Terms &amp; Conditions</p>

			<p className='subHeader md-fontsize'>
				Your Consent to These Terms and Conditions:
			</p>
			<p className='copy md-fontsize'>
				By signing up for the SMS Program, you are consenting to the terms and
				conditions set forth below, with respect to receiving autodialed text
				messages sent from or on behalf of Jazz Pharmaceuticals (“Jazz”).
				Providing your phone number and signing up is your electronic signature
				and provides electronic written consent. Consent is not a condition of
				purchase or use of any Jazz product. Jazz reserves the right to rescind,
				revoke or amend the Program without notice to you.
			</p>

			<p className='subHeader md-fontsize'>
				Use and Disclosure of Your Personal Information:
			</p>
			<p className='copy md-fontsize'>
				Data obtained from you in connection with the Program may include your
				phone number, related carrier information, and elements of pharmacy claim
				information. This information may be used to administer the Program and to
				provide you with Program benefits such as savings offers and Program
				updates and alerts sent directly to your mobile device. Please see our
				Privacy Statement for more general information on how we may collect, use
				and disclose personal information about you.
			</p>

			<p className='subHeader md-fontsize'>Text Messages Sent to You:</p>
			<p className='copy md-fontsize'>
				The Program is valid with most major US carriers, but not all cellular
				phone providers carry the necessary service to participate. Participating
				Carriers: The service is available on AT&T, Sprint, T-Mobile, Verizon
				Wireless, Boost, Cricket, MetroPCS, U.S. Cellular, Virgin Mobile, ACS
				Wireless, Appalachian Wireless, Bluegrass Cellular, Carolina West
				Wireless, Cellcom, C-Spire Wireless (formerly Cellsouth), Cellular One of
				East Central Illinois, Cincinnati Bell Wireless, Cross (dba Sprocket),
				Duet IP, Element Mobile, EpicTouch, GCI Communications, Golden State,
				Hawkeye (Chat Mobility), Hawkeye (NW Missouri Cellular), Illinois Valley
				Cellular, Immix (Keystone Wireless / PC Management), Inland Cellular,
				iWireless, Mobi PCS (Coral Wireless LLC), Mosaic, MTPCS / Cellular One
				(Cellone Nation), Nex-Tech Wireless, nTelos, Panhandle Telecommunications,
				Peoples Wireless, Pioneer, Plateau, Revol Wireless, Rina – Custer, Rina –
				All West, Rina – Cambridge Telecom Coop, Rina – Eagle Valley Comm, Rina –
				Farmers Mutual Telephone Co, Rina – Nucla Nutria Telephone Co, Rina –
				Silver Star, Rina – South Central Comm, Rina – Syringa, Rina – UBET, Rina
				– Manti, South Canaan / CellularOne of NEPA, Thumb Cellular, Union
				Wireless, United, Viaero Wireless, West Central Wireless, Leaco,
				Nemont/Sagebrush. T-Mobile is not liable for delayed or undelivered
				messages. The service is offered on an “as-is” basis and may not be
				available in all areas at all times and may not continue to work in the
				event of product, software, coverage or other changes made by your
				wireless carrier. Carriers are not responsible for delayed or undelivered
				messages. Check your phone capabilities for specific text messaging
				instructions. There is no fee charged to you for receiving Program
				messages that is payable to Jazz or anyone acting on its behalf; however,
				your carrier’s message and data rates may apply depending on your
				individual wireless pricing plan, in accordance with your wireless
				customer agreement. You are responsible for all applicable taxes. Consult
				your wireless service provider regarding its pricing plans.
			</p>

			<p className='subHeader md-fontsize'>Participant Opt-Out or Help:</p>
			<p className='copy space-under md-fontsize'>
				You may opt-out from participating in the Program at any time. Text{' '}
				<b>STOP</b>{' '}
				to 98637 or reply <b>STOP</b> to any message to opt-out. For help, text{' '}
				<b>HELP</b> to 98637 or reply <b>HELP</b> to any message or call JazzCares at&nbsp;
				<a href="tel:+18335335299" className='link'>1-833-533-JAZZ</a> (5299).
			</p>
		</section>
	</Layout>
);

export default TermsConditions;
